.Work {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.Work-section {
  font-size: 12px;
  color: var(--font-color);
  letter-spacing: 2px;
  text-align: left;
  justify-content: left;
  text-transform: uppercase;
  line-height: 2em;
}

.Work-list {
  text-align: justify;
  line-height: 15px;
}

.Work-link {
  color: var(--link-color);
  text-decoration: none;
}

.Work-warning {
  font-weight: bold;
}