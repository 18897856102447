:root {
    --main-bg-color: #1D2021;
    --font-color: #c9cacc;
    --link-color: #fbd01d;
    --hover-item-color: rgba(255, 255, 255, 0.22);

    /* cycle colors */
    --cycle-first-color: #38844B;
    --cycle-second-color: #1E5ADF;
    --cycle-third-color: #C63D2D;
    --cycle-fourth-color: #F4AA3C;
}

body {
    background-color: var(--main-bg-color);
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBar {
    z-index: 100;
    width: 100%;
    height: 80px;
    flex-direction: column;
    background: inherit;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
    color: #3f3f3f;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    line-height: 1em;
    overflow: hidden;
    background-color: var(--main-bg-color);
    position: fixed;
    /* Set the navbar to fixed position */
    top: 0;
    /* Position the navbar at the top of the page */
}

.NavBar-link {
    margin: 12px;
    text-decoration: none;
    overflow: visible;
    display: inline-block;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
}

.item:hover {
    border-radius: 10px;
    background-color: var(--hover-item-color);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
    color: var(--font-color);
}

.item {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    padding: 20px 15px;
}

.NavBar a.NavBar-link:hover, a.NavBar-link:active {
    text-decoration: none;
    -webkit-user-select: none;
            user-select: none;
    color: var(--font-color);
}

.NavBar a.NavBar-link:link, a.NavBar-link:visited {
    -webkit-user-select: none;
            user-select: none;
    text-decoration: none;
    color: var(--font-color);
}

/* desktop-x-small */

@media all and (min-width: 0) and (max-width: 480px) {
    .NavBar {
        height: 60px;
    }

    .NavBar-link {
        font-size: 10px;
        margin: 11px;
        text-decoration: none;
        overflow: visible;
        display: inline-block;
        cursor: pointer;
        border-radius: 10px;
        transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
    }
}
.About {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-size: 32px;
    color: var(--font-color);
    letter-spacing: 2px;
}

.About-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Profile Picture Column */

.About-profile-column {
    padding-left: 15px;
    padding-right: 30px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 0 1;
    display: block;
}

.About-profile-picture {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 300px;
    width: 300px;
    max-height: 300px;
    max-width: 100%;
    min-height: 300px;
    min-width: 300px;
    border-radius: 360px;
    border: 4px solid rgba(76, 175, 80, 0.6);
    animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: colorcyclepic;
}

@media all and (max-width: 750px) {
    .About {
        display: block;
        padding-top: 80px;
    }

    .About-profile-picture {
        display: none;
    }

    .About-profile-column {
        display: none;
    }
}

/* Biography Column */

.About-bio-column {
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 20px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2 1;
}

.About-text-main {
    display: inline;
    font-size: 36px;
    line-height: 1.5em;
}

.About-text-subtitle {
    font-size: 24px;
}

.About-text {
    font-size: 12px;
    line-height: 2em;
    text-transform: uppercase;
}

.About-relocate {
    font-size: 12px;
    line-height: 2em;
    text-transform: uppercase;
}

.About-text-name {
    display: inline;
    font-size: 36px;
    line-height: 1.5em;
    animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: colorcyclepic;
}

.Inline {
    display: inline;
}

.About-link {
    color: var(--link-color);
    text-decoration: none;
}

@keyframes colorcyclepic {
    0% {
        color: var(--cycle-first-color);
        border-color: var(--cycle-first-color);
    }
    25% {
        color: var(--cycle-second-color);
        border-color: var(--cycle-second-color);
    }
    50% {
        color: var(--cycle-third-color);
        border-color: var(--cycle-third-color);
    }
    75% {
        color: var(--cycle-fourth-color);
        border-color: var(--cycle-fourth-color);
    }
    100% {
        color: var(--cycle-first-color);
        border-color: var(--cycle-first-color);
    }
}
.Footer {
  padding-top: 50px;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: var(--font-color);
  letter-spacing: 2px;
}

.Footer-link {
  color: var(--link-color);
  text-decoration: none;
}

.Inline {
  display: inline;
}

.Footer-copyright {
  padding: 30px;
  font-size: 11px;
}

.Footer-socials {
  padding: 30px;
}

/* desktop-x-small */

@media all and (min-width: 320px) and (max-width: 374px) {
  .Footer-socials {
    padding: 20px;
  }
  .Footer-reach-text {
    display: none;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    display: flex;
    width: 75%;
    padding: 10%;
    vertical-align: middle;
  }
}

/* desktop-small */

@media all and (min-width: 375px) and (max-width: 475px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

@media all and (min-width: 476px) and (max-width: 768px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

@media all and (min-width: 769px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 15px;
    display: inline-block;
    vertical-align: middle;
  }
}

@keyframes colorcycle {
    0% {
        fill: var(--cycle-first-color);
    }
    25% {
        fill: var(--cycle-second-color);
    }
    50% {
        fill: var(--cycle-third-color);
    }
    75% {
        fill: var(--cycle-fourth-color);
    }
    100% {
        fill: var(--cycle-first-color);
    }
}
.SocialBadge {
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-name: colorcycle;
    animation-duration: 10s;
    text-decoration: none;
    fill: white;
    padding: 20px;
    margin-right: 20px;
    overflow: visible;
    display: inline-block;
    cursor: pointer;
    border-radius: 360px;
    transition: all 0.6s cubic-bezier(.25,.8,.25,1);
}

.SocialBadge:hover {
    box-shadow: 0 0px 0px rgba(0,0,0,0.25), 0px 10px 10px rgba(0,0,0,0.22);
}

.Inline {
    display: inline;
}

@keyframes colorcycle {
    0% {
        fill: #38844B;
    }
    25% {
        fill: #1E5ADF;
    }
    50% {
        fill: #C63D2D;
    }
    75% {
        fill: #F4AA3C;
    }
    100% {
        fill: #38844B;
    }
}
.Work {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.Work-section {
  font-size: 12px;
  color: var(--font-color);
  letter-spacing: 2px;
  text-align: left;
  justify-content: left;
  text-transform: uppercase;
  line-height: 2em;
}

.Work-list {
  text-align: justify;
  line-height: 15px;
}

.Work-link {
  color: var(--link-color);
  text-decoration: none;
}

.Work-warning {
  font-weight: bold;
}
