.Footer {
  padding-top: 50px;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: var(--font-color);
  letter-spacing: 2px;
}

.Footer-link {
  color: var(--link-color);
  text-decoration: none;
}

.Inline {
  display: inline;
}

.Footer-copyright {
  padding: 30px;
  font-size: 11px;
}

.Footer-socials {
  padding: 30px;
}

/* desktop-x-small */

@media all and (min-width: 320px) and (max-width: 374px) {
  .Footer-socials {
    padding: 20px;
  }
  .Footer-reach-text {
    display: none;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    display: flex;
    width: 75%;
    padding: 10%;
    vertical-align: middle;
  }
}

/* desktop-small */

@media all and (min-width: 375px) and (max-width: 475px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

@media all and (min-width: 476px) and (max-width: 768px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

@media all and (min-width: 769px) {
  .Footer-mail {
    padding-top: 20px;
  }
  .Footer-mail-icon {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: colorcycle;
    animation-duration: 10s;
    padding: 15px;
    display: inline-block;
    vertical-align: middle;
  }
}

@keyframes colorcycle {
    0% {
        fill: var(--cycle-first-color);
    }
    25% {
        fill: var(--cycle-second-color);
    }
    50% {
        fill: var(--cycle-third-color);
    }
    75% {
        fill: var(--cycle-fourth-color);
    }
    100% {
        fill: var(--cycle-first-color);
    }
}