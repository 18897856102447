:root {
    --main-bg-color: #1D2021;
    --font-color: #c9cacc;
    --link-color: #fbd01d;
    --hover-item-color: rgba(255, 255, 255, 0.22);

    /* cycle colors */
    --cycle-first-color: #38844B;
    --cycle-second-color: #1E5ADF;
    --cycle-third-color: #C63D2D;
    --cycle-fourth-color: #F4AA3C;
}

body {
    background-color: var(--main-bg-color);
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
