.About {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-size: 32px;
    color: var(--font-color);
    letter-spacing: 2px;
}

.About-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Profile Picture Column */

.About-profile-column {
    padding-left: 15px;
    padding-right: 30px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 0;
    display: block;
}

.About-profile-picture {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 300px;
    width: 300px;
    max-height: 300px;
    max-width: 100%;
    min-height: 300px;
    min-width: 300px;
    border-radius: 360px;
    border: 4px solid rgba(76, 175, 80, 0.6);
    animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: colorcyclepic;
}

@media all and (max-width: 750px) {
    .About {
        display: block;
        padding-top: 80px;
    }

    .About-profile-picture {
        display: none;
    }

    .About-profile-column {
        display: none;
    }
}

/* Biography Column */

.About-bio-column {
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 20px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}

.About-text-main {
    display: inline;
    font-size: 36px;
    line-height: 1.5em;
}

.About-text-subtitle {
    font-size: 24px;
}

.About-text {
    font-size: 12px;
    line-height: 2em;
    text-transform: uppercase;
}

.About-relocate {
    font-size: 12px;
    line-height: 2em;
    text-transform: uppercase;
}

.About-text-name {
    display: inline;
    font-size: 36px;
    line-height: 1.5em;
    animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: colorcyclepic;
}

.Inline {
    display: inline;
}

.About-link {
    color: var(--link-color);
    text-decoration: none;
}

@keyframes colorcyclepic {
    0% {
        color: var(--cycle-first-color);
        border-color: var(--cycle-first-color);
    }
    25% {
        color: var(--cycle-second-color);
        border-color: var(--cycle-second-color);
    }
    50% {
        color: var(--cycle-third-color);
        border-color: var(--cycle-third-color);
    }
    75% {
        color: var(--cycle-fourth-color);
        border-color: var(--cycle-fourth-color);
    }
    100% {
        color: var(--cycle-first-color);
        border-color: var(--cycle-first-color);
    }
}