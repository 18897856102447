.NavBar {
    z-index: 100;
    width: 100%;
    height: 80px;
    flex-direction: column;
    background: inherit;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
    color: #3f3f3f;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    line-height: 1em;
    overflow: hidden;
    background-color: var(--main-bg-color);
    position: fixed;
    /* Set the navbar to fixed position */
    top: 0;
    /* Position the navbar at the top of the page */
}

.NavBar-link {
    margin: 12px;
    text-decoration: none;
    overflow: visible;
    display: inline-block;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
}

.item:hover {
    border-radius: 10px;
    background-color: var(--hover-item-color);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    color: var(--font-color);
}

.item {
    position: relative;
    user-select: none;
    padding: 20px 15px;
}

.NavBar a.NavBar-link:hover, a.NavBar-link:active {
    text-decoration: none;
    user-select: none;
    color: var(--font-color);
}

.NavBar a.NavBar-link:link, a.NavBar-link:visited {
    user-select: none;
    text-decoration: none;
    color: var(--font-color);
}

/* desktop-x-small */

@media all and (min-width: 0) and (max-width: 480px) {
    .NavBar {
        height: 60px;
    }

    .NavBar-link {
        font-size: 10px;
        margin: 11px;
        text-decoration: none;
        overflow: visible;
        display: inline-block;
        cursor: pointer;
        border-radius: 10px;
        transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
    }
}